<template>
  <div class="mt-1 mx-3">
    <registries>
    </registries>
  </div>
</template>

<script>
import Registries from '@/components/wallet/registries/index.vue'
export default {
  props: {
    msg: String
  },
  components: {
    Registries,
  },
  created() {
  }
}
</script>
