<template>
  <div class="mt-1 mx-3">
    <register-customer> </register-customer>
  </div>
</template>

<script>
import RegisterCustomer from "@/components/wallet/registries/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    RegisterCustomer,
  },
  created() {
    this.notifyQueue({
      text: "Crea Anagrafica",
      path: "registries.create",
      level: 4,
    });
  },
};
</script>
