<template>
  <div class="mt-1">
    <b-card header="Privacy - PEASY" header-tag="header" class="mt-1">
      <b-card-text>
        <b-row class="mx-1">
          <div class="form-group col-md-6">
            <base-select
              vid="intermediary_consent"
              :name="beECForm.intermediary_consent.label"
              :label="beECForm.intermediary_consent.label"
              v-model="inputVal.intermediary_consent"
              :options="beECForm.intermediary_consent.options"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              :rules="rules.intermediary_consent"
            />
          </div>
          <div class="form-group col-md-6">
            <base-select
              vid="traditional_consent"
              :name="beECForm.traditional_consent.label"
              :label="beECForm.traditional_consent.label"
              v-model="inputVal.traditional_consent"
              :options="beECForm.traditional_consent.options"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              :rules="rules.traditional_consent"
            />
          </div>
          <div class="form-group col-md-6">
            <base-select
              vid="electronic_consent"
              :name="beECForm.electronic_consent.label"
              :label="beECForm.electronic_consent.label"
              v-model="inputVal.electronic_consent"
              :options="beECForm.electronic_consent.options"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              :rules="rules.electronic_consent"
            />
          </div>
          <div class="form-group col-md-6">
            <base-select
              vid="thirdparty_consent"
              :name="beECForm.thirdparty_consent.label"
              :label="beECForm.thirdparty_consent.label"
              v-model="inputVal.thirdparty_consent"
              :options="beECForm.thirdparty_consent.options"
              :taggable="false"
              :multiple="false"
              :closeOnSelect="true"
              :rules="rules.thirdparty_consent"
            />
          </div>
        </b-row>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import BaseSelect from "@/components/form/BaseSelect";
// import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  props: {
    beECForm: {
      type: Object,
      default() {
        return {};
      },
    },
    rules: {
      type: Object,
      default() {
        return {
          intermediary_consent: {},
          traditional_consent: {},
          electronic_consent: {},
          thirdparty_consent: {},
        };
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    repository: {
      type: String,
    },
    value: null,
  },
  components: {
    BaseSelect,
  },
  beforeMount() {},
  methods: {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
